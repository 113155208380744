// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from "react";
// import Typography from "@material-ui/core/Typography";
// import Expandable from "../Design/Expandable";
// import DataTable from "../Design/DataTable";
// import Modal from "../Design/Modal";
// import { GOOGLE_API_KEY, orderStatus } from "../../constants/constants";
// import isEmpty from "lodash/isEmpty";
// import OrderTrackerMap from "./TrackingOrder";

// const OrdersResume = ({
//   branch,
//   orders,
//   title,
//   cssClass,
//   headerCssClass,
//   useTime = true,
//   showTime = false,
// }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [googleLoaded, setGoogleLoaded] = useState(false);
//   const [currOrder, setCurrOrder] = useState();
//   const [etas, setEtas] = useState({});
//   const [etasBiker, setEtasBiker] = useState({});

//   const calculateETAWithClient = (locationRef, branch, orderId) => {
//     if (!googleLoaded) {
//       return;
//     }

//     const service = new window.google.maps.DistanceMatrixService();
//     const request = {
//       origins: [`${branch._lat},${branch._long}`],
//       destinations: [`${locationRef._lat},${locationRef._long}`],
//       travelMode: "DRIVING",
//     };

//     service.getDistanceMatrix(request, (response, status) => {
//       if (status === "OK") {
//         const element = response.rows[0].elements[0];
//         if (element.status === "OK") {
//           const durationInSeconds = element.duration.value;
//           const estimatedArrivalTime = new Date(
//             Date.now() + durationInSeconds * 1000
//           );

//           const etaTime = estimatedArrivalTime.toLocaleTimeString("es-ES", {
//             hour: "2-digit",
//             minute: "2-digit",
//           });

//           setEtas((prevEtas) => ({ ...prevEtas, [orderId]: etaTime }));
//         } else {
//           setEtas((prevEtas) => ({
//             ...prevEtas,
//             [orderId]: "FALTA DE INFORMACION",
//           }));
//         }
//       } else {
//         setEtas((prevEtas) => ({ ...prevEtas, [orderId]: "Calculando" }));
//       }
//     });
//   };

//   const calculateETAWithClientBikers = (actualPosition, branch, orderId) => {
//     if (!googleLoaded) {
//       return;
//     }

//     const service = new window.google.maps.DistanceMatrixService();
//     const request = {
//       origins: [`${actualPosition._lat},${actualPosition._long}`],
//       destinations: [`${branch.lat},${branch.lng}`],
//       travelMode: "DRIVING",
//     };

//     service.getDistanceMatrix(request, (response, status) => {
//       if (status === "OK") {
//         const element = response.rows[0].elements[0];
//         if (element.status === "OK") {
//           const durationInSeconds = element.duration.value;
//           const estimatedArrivalTime = new Date(
//             Date.now() + durationInSeconds * 1000
//           );

//           const etaTime = estimatedArrivalTime.toLocaleTimeString("es-ES", {
//             hour: "2-digit",
//             minute: "2-digit",
//           });

//           setEtasBiker((prevEtas) => ({ ...prevEtas, [orderId]: etaTime }));
//         } else {
//           setEtasBiker((prevEtas) => ({
//             ...prevEtas,
//             [orderId]: "FALTA DE INFORMACION",
//           }));
//         }
//       } else {
//         setEtasBiker((prevEtas) => ({ ...prevEtas, [orderId]: "Calculando" }));
//       }
//     });
//   };

//   const onModalClose = () => {
//     setIsModalOpen(false);
//   };

//   const getHeaders = () => {
//     let headers = [
//       {
//         id: "orderId",
//         name: "orden",
//         style: {
//           width: "20%",
//         },
//       },
//       {
//         id: "aliasName",
//         name: "MOTORISTA",
//         style: {
//           width: "20%",
//         },
//       },
//     ];
//     if (useTime) {
//       headers = [
//         ...headers,
//         {
//           id: "arriveAt",
//           name: "ARRIBO APROXIMADO",
//           style: {
//             width: "25%",
//           },
//         },
//       ];
//     } else {
//       headers = [
//         ...headers,
//         {
//           id: "orderStatus",
//           name: "estado",
//           style: {
//             width: "25%",
//           },
//         },
//       ];
//     }
//     if (showTime) {
//       headers = [
//         ...headers,
//         {
//           id: "arriveAtBranch",
//           name: "Llega En",
//           style: {
//             width: "25%",
//           },
//         },
//       ];
//     }
//     headers = [
//       ...headers,
//       {
//         id: "lookForOrder",
//         name: "",
//         style: {
//           width: "15%",
//         },
//       },
//     ];
//     return headers;
//   };

//   useEffect(() => {
//     if (orders && orders.length > 0) {
//       orders.forEach((order) => {
//         if (order.branch && order.locationRef) {
//           calculateETAWithClient(
//             order.locationRef,
//             order.alias.actualPosition,
//             order.id
//           );
//           calculateETAWithClientBikers(
//             order.alias.actualPosition,
//             order.branch,
//             order.id
//           );
//         }
//       });
//     }
//   }, [googleLoaded, orders]);

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
//     script.async = true;
//     script.onload = () => setGoogleLoaded(true);
//     document.head.appendChild(script);
//   }, []);

//   useEffect(() => {
//     if (!isEmpty(currOrder) && !isEmpty(orders)) {
//       setCurrOrder(orders.find((order) => order.id === currOrder.id));
//     }
//   }, [orders]);

//   return (
//     <div>
//       {isModalOpen && currOrder && (
//         <Modal
//           isOpened={isModalOpen}
//           onClose={onModalClose}
//           body={
//             <OrderTrackerMap
//               bikerLocation={currOrder.alias.actualPosition}
//               orderLocation={currOrder.locationRef}
//               order={currOrder}
//               onModalClose={onModalClose}
//             />
//           }
//         />
//       )}
//       <Expandable
//         headerCssClass={headerCssClass}
//         title={
//           <React.Fragment>
//             <Typography variant="h6">
//               <strong className="page-title">{title}</strong>
//             </Typography>
//           </React.Fragment>
//         }
//         defaultExpanded
//       >
//         <div className={cssClass}>
//           <DataTable
//             align="left"
//             headers={getHeaders()}
//             rows={(orders || []).map((order) => {
//               let lookForOrder;
//               const etaStatus = etas[order.id];
//               if (
//                 etaStatus !== "FALTA DE INFORMACION" &&
//                 !isEmpty(order.alias) &&
//                 !isEmpty(order.alias.actualPosition) &&
//                 ((order.status === orderStatus.ACCEPTED && !isEmpty(branch)) ||
//                   (order.status === orderStatus.GOING &&
//                     !isEmpty(order.locationRef)))
//               ) {
//                 lookForOrder = (
//                   <div className="company-branches-links">
//                     <div
//                       onClick={(e) => {
//                         setCurrOrder(order);
//                         setIsModalOpen(true);
//                       }}
//                     >
//                       Rastrear
//                     </div>
//                   </div>
//                 );
//               }
//               let customOrderStatus;
//               if (order.status === orderStatus.ASSIGNED) {
//                 customOrderStatus = "ASIGNADO";
//               } else if (order.status === orderStatus.ACCEPTED) {
//                 customOrderStatus = "ACEPTADO";
//               } else if (order.status === orderStatus.RECEIVING) {
//                 customOrderStatus = "RECIBIENDO EN SUCURSAL";
//               } else {
//                 customOrderStatus = order.status;
//               }
//               return {
//                 ...order,
//                 time: order.time,
//                 arriveAtBranch:
//                   order.status === orderStatus.ASSIGNED
//                     ? etasBiker[order.id]
//                     : "",
//                 arriveAt:
//                   (order.status === orderStatus.GOING ||
//                     order.status === orderStatus.ACCEPTED ||
//                     order.status === orderStatus.ASSIGNED) &&
//                   order.time !== undefined
//                     ? etas[order.id]
//                     : "",
//                 orderStatus: customOrderStatus,
//                 circle: order.circle !== undefined ? order.circle : "Esperando",
//                 lookForOrder,
//                 aliasName: (order.alias || {}).name,
//               };
//             })}
//           />
//         </div>
//       </Expandable>
//     </div>
//   );
// };

// export default OrdersResume;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Expandable from "../Design/Expandable";
import DataTable from "../Design/DataTable";
import Modal from "../Design/Modal";
import isEmpty from "lodash/isEmpty";
import OrderTrackerMap from "./TrackingOrder";
import { GOOGLE_API_KEY } from "../../constants/constants";

const OrdersResume = ({ branch, orders, title, cssClass, headerCssClass }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currOrder, setCurrOrder] = useState();

  useEffect(() => {
    if (!window.google || !window.google.maps) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = () => {
        console.log("Google Maps API cargado correctamente.");
      };
      document.head.appendChild(script);
    } else {
      console.log("Google Maps API ya estaba cargado.");
    }
  }, []);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const getHeaders = () => [
    { id: "orderId", name: "Orden", style: { width: "40%" } },
    { id: "aliasName", name: "Motorista", style: { width: "40%" } },
    { id: "lookForOrder", name: "Rastrear", style: { width: "20%" } },
  ];

  return (
    <div>
      {isModalOpen && currOrder && (
        <Modal
          isOpened={isModalOpen}
          onClose={onModalClose}
          body={
            <OrderTrackerMap
              bikerLocation={currOrder.alias.actualPosition}
              orderLocation={currOrder.locationRef}
              order={currOrder}
              onModalClose={onModalClose}
            />
          }
        />
      )}
      <Expandable
        headerCssClass={headerCssClass}
        title={
          <React.Fragment>
            <Typography variant="h6">
              <strong className="page-title">{title}</strong>
            </Typography>
          </React.Fragment>
        }
        defaultExpanded
      >
        <div className={cssClass}>
          <DataTable
            align="left"
            headers={getHeaders()}
            rows={(orders || []).map((order) => {
              let lookForOrder;
              if (!isEmpty(order.alias) && !isEmpty(order.alias.actualPosition)) {
                lookForOrder = (
                  <div className="company-branches-links">
                    <div
                      onClick={() => {
                        setCurrOrder(order);
                        setIsModalOpen(true);
                      }}
                    >
                      Rastrear
                    </div>
                  </div>
                );
              }
              return {
                ...order,
                orderStatus: order.status,
                lookForOrder,
                aliasName: (order.alias || {}).name,
              };
            })}
          />
        </div>
      </Expandable>
    </div>
  );
};

export default OrdersResume;